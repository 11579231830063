<template>
  <div class="booklistbox">
    <column-list :set="teamIsmember==1? true :false" :oData="oData" @changePage="handleChangePage"></column-list>
  </div>
</template>
<script>
import ColumnList from "../components/Columnlist";
import { teamSpecialRequest } from "@/api/project.js";
export default {
  components: {
    ColumnList
  },
  data() {
    return {
      oData: ""
    };
  },
  created() {
    this.getList(this.tid);
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    },
    teamIsmember() {
      return this.$store.state.teamIsmember;
    }
  },
  methods: {
    async getList(tid,val) {
      try {
        val = val || "1";
        const result = await teamSpecialRequest({
          page: val,
          tid:tid
        });
        if (result.status == 200) {
          this.oData = result.data;
        }
      } catch (err) {}
    },
    handleChangePage(val) {
      this.getList(val);
    }
  }
};
</script>
<style  lang="less" scoped>
.booklistbox{
  padding: 0 25px;
  margin-top:-10px;
}
</style>