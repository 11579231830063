<template>
  <div>
    <p v-if="showNomore" class="nomore">暂无数据</p>
    <ul class="booklist" v-loading="loading">
      <li v-for="item in oData.list" v-if='oData'>
        <div class="onebox">
          <router-link tag="div" class="imgbox" :to="{name:'specialColumnArticle',params:{
            Identify:item.Identify
          }}">
            <img :src="item.Cover" alt />
          </router-link>

          <router-link tag="h2" class="titleh2" :to="{name:'specialColumnArticle'}">{{item.Special}}</router-link>
          <!-- <h2 class="titleh2">件提供了三个不同的主</h2> -->
          <p class="textp">{{item.Overview}}</p>
          <div class="setbox" v-show="set">
             <span class="del iconfont iconshanchu3" @click="handelDelBook(item.Id)"></span>
             <router-link
              :to="item.Tid ? {name:'columnInfo',query:{Id:item.Id,tid:item.Tid}}: {name:'columnInfo',query:{Id:item.Id}}"
              tag="span"
              class="edit iconfont iconbianji2"
            ></router-link>
          </div>
        </div>
      </li>
    </ul>
    <el-pagination
      v-if="oData.list && oData.totalCount> oData.pageCount && page"
      background
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="oData.pageCount"
      :current-page="currentPage"
      :total="oData.totalCount"
    ></el-pagination>
  </div>
</template>
<script>
import { specialLeaveRequest } from "@/api/project.js";
export default {
  props: {
    set: {
      set: Boolean,
      required: false
    },
    oData: {
      listData: [String, Object],
      required: false
    },
    page:{
    default:true
    }
  },
  data() {
    return {
      currentPage: 1,
      loading: true,
       showNomore:false
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("changePage", val);
      this.loading = true;
      var _this=this;
      setTimeout(() => {
        _this.loading = false;
      }, 3000);
    },
     async delBook(id) {
      try {
        var _this = this;
        const result = await specialLeaveRequest({
          sid: id
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });

          this.oData.list.some((item, index, arr) => {
            if (id === item.Id) {
             
              _this.oData.list.splice(index, 1);
            }
            return id === item.Id;
          });
        } else {
          this.$message({
            type: "error",
            message: result.message
          });
        }
      } catch (err) {}
    },
    handelDelBook(id) {
      var _this = this;
      this.$confirm("此操作将永久删除该专栏, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.delBook(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
  },
  watch: {
     oData(val) {
      if (val.list.length >= 0) {
        this.loading = false;
      }
      if(val.list.length==0)this.showNomore=true;
    }
  }
};
</script>
<style  lang="less" scoped>
.booklist {
  padding: 15px 0;
  margin:0 -10px;
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  & > li {
    width: 25%;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .onebox {
      background: #fff;
      -webkit-box-shadow: 0px 0px 10px #e0e0e0;
      box-shadow: 0px 0px 10px #e0e0e0;
      padding: 10px;
      margin:10px 0;
    }
    .imgbox {
      margin: -9px -9px 0 -9px;
      padding: 2px;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .titleh2 {
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px;
       cursor: pointer;
    }
    .setbox {
      overflow: hidden;
      margin-top: 5px;
    }
    .iconfont {
      float: right;
      color: #11adcf;
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .textp {
      font-size: 12px;
      color: #666;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      height: 72px;
      -webkit-box-orient: vertical; //溢出隐藏
    }
  }
}
.oneboxzw {
  .imgbox {
    height: 166.38px;
    span {
      width: 100%;
      height: 100%;
      display: block;
      background: #f1f1f1;
    }
  }
}
.el-pagination {
  text-align: right;
  padding:30px 20px;
}
.nomore{
  height:150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>